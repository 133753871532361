import React from "react";
// 加载基础组价
const MonitorLibraryContainer = Loader.loadBusinessComponent("MonitorLibrary", "MonitorLibraryContainer");

const KeyPersonMonitorLibrary = () => {
  const sceneCode = BaseStore.user.appInfo.sceneCode;
  const monitorLabel = Dict.map.monitorLabel[sceneCode];
  const libTypeInfo = {
    libType: 1,
    libLabel: monitorLabel.keyPerson.libLabel,
    libPeople: monitorLabel.keyPerson.libPeople,
    authAction: "keyPersonnelLibraryManage",
    moduleName: "keyPersonnelLibraryView"
  };
  return (
    <MonitorLibraryContainer 
      libTypeInfo={libTypeInfo}
    />
  )
}

export default KeyPersonMonitorLibrary;
